import React, { useState, useEffect } from 'react';
import { Checkbox, Select } from 'antd';
import { Link } from 'gatsby';
import AllCountries from 'react-intl-tel-input/dist/components/AllCountries';
import * as styles from '../../../styles/auth/SimpleSignup.module.scss';
import 'react-intl-tel-input/dist/main.css';
import { Errors, FormData, UserCountry } from '../../../pages/signup';

type Props = {
  formData: FormData,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
  getOtpHandler: (e:React.FormEvent<HTMLFormElement>, f: boolean | undefined) => void,
  accountExists?: boolean
  throughSignupFlow?: boolean,
  userCountry: UserCountry,
  setErrors: React.Dispatch<React.SetStateAction<Errors>>,
  errors: Errors,
}

const SimpleSignupPersonalInfo: React.FC<Props> = ({
  formData,
  setFormData,
  getOtpHandler,
  accountExists,
  throughSignupFlow,
  userCountry,
  setErrors,
  errors,
}) => {
  const [isFormComplete, setFormComplete] = useState(false);

  const allCountries = AllCountries.getCountries();

  const { Option } = Select;

  const checkEmailValidity = (email: string) => {
    const validEmailRegex = new RegExp(
      '^([a-zA-Z0-9+_.-]+)@([a-zA-Z0-9_.-]+)\\.([a-zA-Z0-9]+)$',
    );
    return validEmailRegex.test(email);
  };

  const checkPasswordValidity = (password: string) => {
    if (password.length >= 8) {
      return true;
    }
    return false;
  };

  const setValidation = (field: string, value: string | boolean) => {
    // Condition to avoid  terms and promotions key in error state
    if (!['terms', 'promotions'].includes(field)) {
      setErrors((prevState) => ({
        ...prevState,
        [field]: !value,
      }));
    }
  };

  const inputValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'email': setValidation(name, checkEmailValidity(value));
        break;
      case 'password': setValidation(name, checkPasswordValidity(value));
        break;
      default: setValidation(name, !!value);
    }
  };

  const changeCountry = (value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      country: value,
    }));
  };

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (['terms', 'promotions'].includes(name)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }

    inputValidation(e);
  };

  const checkFormValidity = () => {
    let valid = true;
    const {
      firstName,
      lastName,
      email,
      password,
      terms,
    } = formData;
    // check if fields are entered & valid
    if (!firstName
      || !lastName
      || !checkEmailValidity(email)
      || !checkPasswordValidity(password)
      || !terms) {
      valid = false;
    }
    setFormComplete(valid);
  };

  useEffect(() => {
    checkFormValidity();
  }, [formData]);

  return (
    <div className={`${styles.signupInnerContainer} ${accountExists ? styles.accountExistsContainer : ''}`}>
      <h3>Create your account!</h3>
      <form className={styles.form} id="self-serve-starter-plan" onSubmit={(e) => getOtpHandler(e, throughSignupFlow)}>
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor="firstName">
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={formData.firstName ? formData.firstName : ''}
                autoComplete="off"
                placeholder="First Name"
                className={formData.firstName && (!errors.firstName
                  ? styles.valid : styles.invalid)}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="lastName">
              <input
                id="lastName"
                name="lastName"
                value={formData.lastName ? formData.lastName : ''}
                type="text"
                autoComplete="off"
                placeholder="Last Name"
                className={formData.lastName && (!errors.lastName
                  ? styles.valid : styles.invalid)}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email ? formData.email : ''}
              autoComplete="off"
              placeholder="example@yourcompany.com"
              className={formData.email && (!errors.email
                ? styles.valid : styles.invalid)}
              onChange={handleChange}
            />
          </label>
        </div>
        {userCountry
        && <input className={styles.countryHiddenInput} type="text" name="country" id="country" value={userCountry.countryCode} />}
        {
          accountExists && (
            <p className={styles.accountExists}>
              * Email already exists! Try to
              {' '}
              <Link
                state={
               {
                 throughSignupFlow,
               }
            }
                to="/login"
              >
                log-in.
              </Link>
            </p>
          )
        }
        <div className={styles.formGroup}>
          <label htmlFor="password">
            <input
              id="password"
              name="password"
              type="password"
              value={formData.password ? formData.password : ''}
              autoComplete="off"
              placeholder="Password"
              className={formData.password && (!errors.password
                ? styles.valid : styles.invalid)}
              onChange={handleChange}
            />
          </label>
          {
            errors.password
          && (
          <h6 className={styles.passwordCriteria}>
            Password must be a minimum of 8 characters long.
          </h6>
          )
          }
        </div>
        <div className={`${styles.formGroup} ${styles.hrLine}`}>
          <Select defaultValue="za" onChange={changeCountry}>
            {allCountries.map((country) => (
              <Option key={country.iso2} value={country.iso2 || ''}>
                {country.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            className={styles.checkbox}
            name="terms"
            id="self_serve_terms"
            checked={formData.terms}
            onChange={handleChange}
            value={formData.terms ? 'yes' : 'no'}
          >
            By signing up, you agree to our
            {' '}
            <a className={styles.terms} href="https://www.upskillist.pro/terms/">Terms & Conditions</a>
          </Checkbox>
          <Checkbox
            name="promotions"
            id="self_seve_promotions"
            checked={formData.promotions}
            onChange={handleChange}
            className={styles.checkbox}
            value={formData.promotions ? 'yes' : 'no'}
          >
            Receive promotional emails about events & offers.
          </Checkbox>
        </div>
        <button
          className={styles.cta}
          disabled={!isFormComplete}
          type="submit"
        >
          Sign Up
        </button>
        <div className={styles.hiddenElement}>
          <input type="text" name="company" id="company" value={`${formData.firstName} ${formData.lastName}'s Workspace`} />
        </div>
      </form>
    </div>
  );
};

export default SimpleSignupPersonalInfo;
