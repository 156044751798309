// extracted by mini-css-extract-plugin
export var simpleSignupContainer = "SimpleSignup-module--simpleSignupContainer--1hohH";
export var formContainer = "SimpleSignup-module--formContainer--3kLFq";
export var spin = "SimpleSignup-module--spin--1jU6K";
export var spinnerContainer = "SimpleSignup-module--spinnerContainer--1ChkJ";
export var spinner = "SimpleSignup-module--spinner--1xsjb";
export var signupInnerContainer = "SimpleSignup-module--signupInnerContainer--1Bl8T";
export var message = "SimpleSignup-module--message--11bfZ";
export var form = "SimpleSignup-module--form--3w4lh";
export var otpInputStyle = "SimpleSignup-module--otpInputStyle--3tycn";
export var reactOtpContainer = "SimpleSignup-module--reactOtpContainer--133Sc";
export var otpError = "SimpleSignup-module--otpError--35CRy";
export var otpMessage = "SimpleSignup-module--otpMessage--1f38n";
export var editEmail = "SimpleSignup-module--editEmail--kCusu";
export var resendOtpContainer = "SimpleSignup-module--resendOtpContainer--w1rzH";
export var resendOtp = "SimpleSignup-module--resendOtp--NQtPk";
export var resendOtpCta = "SimpleSignup-module--resendOtpCta--8QEXq";
export var countryHiddenInput = "SimpleSignup-module--countryHiddenInput--GY4Fq";
export var accountExists = "SimpleSignup-module--accountExists--3evdq";
export var formGroup = "SimpleSignup-module--formGroup--3XmL8";
export var label = "SimpleSignup-module--label--3kx8O";
export var valid = "SimpleSignup-module--valid--tN91y";
export var invalid = "SimpleSignup-module--invalid--1e-Ne";
export var disabledDomainInfo = "SimpleSignup-module--disabledDomainInfo--2xs0a";
export var hrLine = "SimpleSignup-module--hrLine--1ewtS";
export var passwordCriteria = "SimpleSignup-module--passwordCriteria--2aoR5";
export var row = "SimpleSignup-module--row--x8qUv";
export var checkboxContainer = "SimpleSignup-module--checkboxContainer--1-7hp";
export var checkbox = "SimpleSignup-module--checkbox--2NgU0";
export var terms = "SimpleSignup-module--terms--T6Ovs";
export var cta = "SimpleSignup-module--cta--1sw7g";
export var hiddenElement = "SimpleSignup-module--hiddenElement--1-iD2";
export var accountExistsContainer = "SimpleSignup-module--accountExistsContainer--1O5Nl";
export var loginText = "SimpleSignup-module--loginText--uQMoS";
export var faq = "SimpleSignup-module--faq--1zpCP";
export var singleSignupInputError = "SimpleSignup-module--singleSignupInputError--2JH66";